.text-card-preview {
  width: 100%;
  margin: 1rem 0;
  padding-bottom: 1rem;
  position: relative;
  border: 1px solid transparent;
}

.text-card-preview:hover {
  border: 1px solid #f5f5f5;
  transition: all 0.5 ease-in-out;
}

.text-card-preview__close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.text-card-preview__close:hover {
  background-color: #f5f5f5;
  transition: 0.5 ease-in-out;
}

.text-card-preview__content {
  position: relative;
  width: 100%;
}

.text-card-preview__content h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.text-card-preview__content-editable {
  max-width: 580px;
  width: 100%;
  padding: 0.5rem;
  outline: none;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  background: transparent;
  border: transparent;
  position: absolute;
  font-size: 2.5em;
  line-height: 1.6;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.text-card-preview__content-editable .highlight {
  background: #fd9bb0;
}

.text-card-preview__colors .list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.text-card-preview__colors .list .list-item {
  height: 40px;
  width: 40px;
  margin: 0.15rem;
  padding: 0.5rem;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.text-card-preview__colors .list .list-item:hover {
  border: 3px solid #cce4ff;
}

.text-card-preview__colors .list .list-item.active {
  border: 3px solid #1da1f2;
}

.text-card-preview__colors .list .list-item input {
  opacity: 0;
  cursor: pointer;
}

.text-card-preview__colors .list .list-item .checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.text-card-preview__colors .list .list-item input:checked~.checkmark {
  border: 3px solid #555;
}

/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_radio */