.re-order {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.re-order__wrapper {
  max-width: 1140px;
  width: 100%;
  padding: 30px 20px;
}

.re-order__header {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.re-order__header h2 {
  color: #555;
}

.re-order__header h2 span {
  font-size: 1.25rem;
  color: #888;
}

.re-order__header-btn {
  display: flex;
  flex-direction: row;
}

.re-order__header-btn button {
  background-color: #555555;
  border: none;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.re-order__header-btn button.light {
  background-color: #e7e7e7;
  color: #555;
  margin: 0 10px;
}

.re-order__header-btn .scroll-to-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(22, 24, 35);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.re-order__header-btn .scroll-to-bottom:hover {
  background: rgb(22, 24, 35);
  color: #fff;
}

.re-order__header-btn .scroll-to-bottom .icon {
  font-size: 22px;
}

.re-order__list-container {
  border-radius: 0.2rem;
  box-shadow: 0.1rem 0.1rem 0.4rem #aaaaaa;
}

.re-order__draggable {
  display: flex;
  flex-direction: column;
}

.re-order__list-item {
  color: #444444;
  padding: 0.8rem 0.3rem;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.re-order__list-item:last-child {
  border-bottom: none;
}

.re-order__list-drag {
  width: 100%;
}

.re-order__list-drag img {
  max-width: 250px;
  width: 100%;
}

.re-order__list-order {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.re-order__list-order .icon {
  cursor: pointer;
  font-size: 24px;
  color: #888;
}

.re-order__list-order-actions {
  position: absolute;
  right: 0;
  top: 100%;
  width: 200px;
  padding: 10px 0;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  -webkit-box-shadow: -1px 4px 30px -25px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 4px 30px -25px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 4px 30px -25px rgba(0, 0, 0, 0.75);
}

.re-order__list-order-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
  color: #555;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.re-order__list-order-item .icon {
  color: #666;
  font-size: 24px;
  margin-right: 8px;
}

.re-order__list-order-item:hover {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  background-color: #f7f7f7;
}

.re-order__list-order-item:hover,
.re-order__list-order-item:hover .icon {
  color: #333;
}

@media only screen and (max-width:768px) {
  .re-order__header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .re-order__header-btn {
    padding-top: 20px;
  }

  .re-order__list-item img {
    max-width: 300px;
  }
}

@media only screen and (max-width:560px) {
  .re-order__list-order {
    right: 10px;
  }
}

/* @media only screen and (max-width:400px) {
  .re-order__list-drag img {
    max-width: 250px;
    width: 100%;
  }
} */