@import "./../../styles/variables.scss";
@import "./../../styles/responsive.scss";

.trade-navbar {
  height: 60px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid $soft-bg;

  @media screen and (max-width:768px) {
    justify-content: end
  }

  h3 {
    color: $dark-color;
    font-size: 24px;
    font-weight: 400;

    @media screen and (max-width:768px) {
      display: none;
    }
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon-wrapper {
      height: 36px;
      width: 36px;
      background-color: $main-bg;
      border: 1px solid $gray-300;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: $gray-100;
        border: 1px solid $gray-500;

        .icon {
          color: $dark-color;
        }
      }

      .icon {
        font-size: 16px;
        color: $gray-500;
      }
    }

    .notification {
      position: relative;

      span {
        background-color: $green-color;
        color: white;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        top: -12px;
        right: -12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }

    .user {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        object-fit: cover;
      }

      span {
        color: $dark-color;

        @media screen and (max-width:768px) {
          display: none;
        }
      }

      .icon {
        color: $gray-600;
      }
    }
  }
}