.blog {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.blog-container {
  width: 1140px;
  padding: 0 20px;
}

.blog-wrapper {
  display: flex;
}

/* Left */
.blog-content__left {
  flex: 2.5;
  position: sticky;
  top: 60px;
  height: calc(100vh - 60px);
}

/* Center */
.blog-content__center {
  flex: 7;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  background-color: #f5f5f5;
}

.blog-content__center-container {
  padding-top: 0;
}

/* Right */
.blog-content__right {
  flex: 2.5;
  position: sticky;
  top: 60px;
  height: calc(100vh - 60px);
}

.blog-content__right-container {
  padding: 10px 0 10px 20px;
}

.blog-content__right-container h3 {
  color: #555;
  font-size: 1.375rem;
  margin-bottom: 0.5rem;
}

.blog-content__right-container h3:last-of-type {
  color: #555;
  padding-top: 20px;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .blog-container {
    width: 1140px;
    padding: 0;
  }

  .blog-content__left {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .blog-container {
    background-color: #f5f5f5;
  }

  .blog-content__left {
    display: none;
  }

  .blog-content__center {
    flex: 1;
    border: none;
  }

  .blog-content__right {
    display: none;
  }
}