.info {
  padding-left: 0;
}

.info-detail {
  font-size: 14px;
  color: rgb(22, 24, 35);
  line-height: 1.5;
  margin-bottom: 8px;
}

.info-detail a {
  color: rgba(22, 24, 35, 0.5);
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: inline-block;
  margin-right: 6px;
  margin-top: 5px;
  transition: color 250ms ease 0s;
}

.info-detail a:hover {
  text-decoration: underline;
}

.copy-right {
  color: rgba(22, 24, 35, 0.5);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: inline-block;
  margin-right: 6px;
  margin-top: 5px;
}