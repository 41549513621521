@import "./../../../styles/variables.scss";
@import "./../../../styles/responsive.scss";

.trade-home {
  width: 100%;

  .chart {
    min-height: 500px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);

    .header {
      display: flex;
      justify-content: space-between;

      .left {
        h5 {
          color: $gray-500;
        }

        p {
          color: $gray-800;
          font-size: 14px;
          font-weight: 600;

          span {
            font-size: 12px;
            font-weight: 600;
            color: $green-color;

            &.negative {
              color: $red-darken-color;
            }
          }
        }
      }

      .right {
        display: flex;
        gap: 8px;
        padding: 2px;
        border-radius: 8px;
        background-color: $gray-200;

        button {
          outline: none;
          border: none;
          background: none;
          cursor: pointer;
          padding: 0 8px;
          font-size: 10px;
          color: $gray-500;
          font-weight: 600;

          &.active {
            border-radius: 8px;
            color: $gray-800;
            background-color: #fff;
          }
        }
      }
    }

    .chart-js {
      margin-top: 20px;
      height: 450px;
      width: 100%;
    }
  }
}