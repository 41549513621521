.reset-password {
  display: flex;
  justify-content: center;
}

.reset-password__container {
  max-width: 1140px;
  width: 100%;
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.reset-password__home {
  position: absolute;
  top: -20px;
  right: 20px;
  height: 36px;
  width: 36px;
  border: 1px solid #7b8798;
  border-radius: 0.5rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8492a6;
}

.reset-password__home:hover {
  background-color: #6e7885;
  transition: 0.5s;
}

.reset-password__home .icon {
  color: #fff;
  font-size: 20px;
}

.reset-password__image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 6;
  padding: 20px;
}

.reset-password__image img {
  /* max-width: 500px; */
  max-height: 400px;
  width: 100%;
  padding: 20px;
}

.reset-password__content {
  flex: 6;
  width: 100%;
  /* width: 380px; */
  padding: 20px 0;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.reset-password__content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.reset-password__content-container h4 {
  color: #161c2d;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.reset-password__content-container p {
  color: #8492a6;
  padding: 10px 20px;
}

.reset-password__form {
  padding: 10px 20px;
  width: 100%;
}

.reset-password__form .form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.reset-password__form .form-control label {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.reset-password__form .form-control label span {
  color: #e43f52
}

.reset-password__form .form-control .form-icon {
  position: relative;
}

.reset-password__form .form-control .form-icon .icon {
  position: absolute;
  color: #333;
  top: 0.5rem;
  left: 1rem;
}

.reset-password__form .form-control .form-icon input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  padding-left: 3rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.reset-password__form .form-control .form-icon input:focus {
  outline: none;
  border: 1px solid #aaa;
}

.reset-password__form .form-control .form-icon input.is-valid {
  border-color: #0cad6a;
}

.reset-password__form .form-control .form-icon input.is-invalid {
  border-color: #dc3545;
}

.reset-password__form .form-control .reset-invalid-feedback {
  font-size: 14px;
  color: #dc3545;
  padding-top: 0.25rem;
}

.reset-password__form .reset-password__action button {
  width: 100%;
  padding: 8px 20px;
  color: #fff;
  font-size: 1rem;
  outline: none;
  border: 1px solid #2f55d4;
  border-radius: 6px;
  background-color: #2f55d4;
  cursor: pointer;
}

.reset-password__form .reset-password__action button:hover {
  background-color: #2443ac;
  border-color: #2443ac;
  transition: 0.5s;
}

.reset-password__form .reset-password__action button:disabled {
  border: 1px solid #2f55d4;
  background-color: #4566d8;
  cursor: not-allowed;
}

.reset-password__form .reset-password__bottom p {
  color: #555;
}

.reset-password__form .reset-password__bottom a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

@media screen and (max-width:776px) {
  .reset-password__container {
    flex-direction: column;
  }

  .reset-password__image img {
    max-height: 300px;
  }
}

@media screen and (max-width:500px) {
  .reset-password__image img {
    max-height: 200px;
  }

  .reset-password__content-container {
    padding-left: 0;
    padding-right: 0;
  }
}