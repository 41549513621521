@import "./variables.scss";
@import "./responsive.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}