.navbar {
  display: flex;
  justify-content: space-around;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  height: 60px;
  width: 100%;
  top: 0;
  position: fixed;
  align-items: center;
  background: rgba(255, 255, 255, 1.0);
  z-index: 9;
  right: 0px;
}

.navbar__wrapper {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  max-width: 1150px;
  padding-right: 20px;
  padding-left: 20px;
}

.navbar__logo {
  margin-right: 0;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.search-bar-wrapper {
  margin: 0 15px;
}

.search-bar {
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: row;
  padding: 12px 16px;
  background: rgba(22, 24, 35, 0.06);
  border-radius: 92px;
  position: relative;
  overflow: hidden;
}

.search-input {
  font-size: 16px;
  line-height: 22px;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  width: 292px;
  caret-color: rgba(254, 44, 85, 1.0);
  appearance: textfield;
}

.search-input:focus {
  outline: none;
}

.search-line {
  width: 1px;
  height: 28px;
  margin: -3px 0;
  background: rgba(22, 24, 35, 0.12);
}

.search-button {
  padding: 11px 16px 11px 12px;
  margin: -12px -16px;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  margin-left: 0;
  display: flex;
  align-items: center;
}

.search-button:hover {
  background-color: rgba(22, 24, 35, 0.03);
}

.search-button .icon {
  color: rgba(22, 24, 35, 0.34);
}

.navbar__menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.navbar__menu--icon {
  position: relative;
}

.navbar__menu--icon:not(:first-child):not(:last-child) {
  margin-left: 24px;
}

.navbar__menu--icon a {
  font-size: 24px;
  color: rgb(22, 24, 35);
  text-decoration: none;
  cursor: pointer;
}

.navbar__menu--icon .tooltiptext {
  visibility: hidden;
  position: absolute;
  background-color: #666;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  padding: 8px 8px;
  border-radius: 8px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-bottom {
  top: 130%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #666 transparent;
}

.navbar__menu--icon:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.navbar__menu--icon img {
  margin-left: 30px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navbar__menu--icon .tooltiptext-profile {
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  white-space: nowrap;
  margin-right: 16px;
  border-radius: 8px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(232, 232, 232, 1);
  -moz-box-shadow: 0px 0px 5px 2px rgba(232, 232, 232, 1);
  box-shadow: 0px 0px 5px 2px rgba(232, 232, 232, 1);
}

.navbar__menu--text:not(:last-child) {
  margin-right: 24px;
}

.navbar__menu--text a {
  font-size: 16px;
  color: rgb(22, 24, 35);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__menu--text .btn-nav {
  color: rgba(255, 255, 255, 1.0);
  background-color: rgba(254, 44, 85, 1.0);
  border-radius: 4px;
  min-height: 36px;
  padding: 6px 8px;
  font-weight: 600;
  line-height: 22px;
  margin-left: 16px;
  min-width: 100px;
}

.navbar__menu--text .btn-nav:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #FE2C55;
}


.tooltiptext-profile-list {
  display: flex;
  flex-direction: column;
}

.tooltiptext-profile-list a {
  display: flex;
  font-size: 18px;
  color: #333;
  text-decoration: none;
}

.tooltiptext-profile-list a .icon {
  font-size: 20px;
  margin-right: 8px;
}

.tooltiptext-profile-list-item {
  cursor: pointer;
  padding: 10px 30px 10px 20px;
}

.tooltiptext-profile-list-item:first-child {
  margin-top: 8px;
}

.tooltiptext-profile-list-item:last-child {
  margin-bottom: 8px;
  border-top: 1px solid #e6e6e6;
}

.tooltiptext-profile-list-item:hover {
  background-color: #f8f8f8;
  transition: background-color 0.3s;
}

.tooltiptext-profile-bottom {
  top: 140%;
  right: 50%;
  transform: translateX(30%);
}

.tooltiptext-profile-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 8%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #e8e8e8 transparent;
}

.navbar__menu--icon:hover .tooltiptext-profile {
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 992px) {
  .navbar__logo .logo-sm {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .navbar__logo .logo {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .search-bar {
    display: none;
  }
}

@media only screen and (max-width:500px) {
  .navbar__menu--icon:not(:first-child):not(:last-child) {
    margin-left: 16px;
  }

  .navbar__menu--icon img {
    margin-left: 20px;
  }

  .navbar__wrapper {
    padding-left: 10px;
    padding-right: 14px;
  }
}