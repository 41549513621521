.tags-container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.tags-wrapper {
  max-width: 1140px;
  padding: 30px 20px 0px 20px;
  width: 100%;
}

.tags-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tags-move {
  border: none;
  background: none;
  position: relative;
  cursor: pointer;
  color: #606060;
}

.tags-move:hover {
  color: #404040;
}

.tags-move:first-child {
  padding-right: 0px;
}

.tags-move:first-child::after {
  width: 150%;
  content: '';
  display: block;
  position: absolute;
  top: -4px;
  left: 20px;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 69%);
  height: 135%;
}

.tags-move:last-child {
  padding-left: 0px;
}

.tags-move:last-child::after {
  width: 150%;
  content: '';
  display: block;
  position: absolute;
  top: -4px;
  right: 20px;
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 69%);
  height: 135%;
}

.tags-move .icon {
  font-size: 20px;
}

.tags {
  display: flex;
  overflow-x: hidden;
  padding: 0 20px;
  width: 100%;
}

.tags:hover {
  overflow-x: hidden;
  transition: overflow-x 0.3s ease-in-out;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  word-wrap: none;
  white-space: nowrap;
  border: 1px solid #ccc;
  border-radius: 900px;
  background-color: #f2f2f2;
  padding: 0.4rem 1rem;
  font-size: 0.9rem;
  color: #444;
  cursor: pointer;
  transition: background-color 150ms;
}

.tag:hover {
  background-color: #ddd;
}

.tag.active {
  background-color: #606060;
  color: #fff;
  border-color: #eee;
}

.tag.active:hover {
  background-color: #404040;
}

.tag:first-child {
  padding: 0.4rem 1.2rem;
}

.tag:last-child {
  margin: 0;
}

.tag .icon {
  color: #fe2c55;
}