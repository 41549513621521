.cards-container {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}

.cards-wrapper {
  width: 1140px;
  padding: 30px 20px;
}

.cards {
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 60px));
  grid-gap: 40px;
}

.card {
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.card:hover {
  transform: translateY(-7px);
}

.text--medium {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #000;
}

.card__image-container {
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}

.card__image-container img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card__image-container .card__tag {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
  font-size: 16px;
  color: #ccc;
  display: flex;
  align-items: center;
  z-index: 1;
}

.card__content {
  padding: 20px;
}

@media only screen and (max-width:997px) {
  .card {
    grid-column-end: span 6;
  }
}

@media only screen and (max-width:768px) {
  .card {
    grid-column-end: span 12;
  }
}

@media only screen and (max-width:500px) {
  .cards-wrapper {
    padding: 20px 10px;
  }

  .cards {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
  }

  .card {
    grid-column-end: span 6;
  }
}