.post-tags {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}

.post-tag {
  display: block;
  width: fit-content;
  max-width: 100%;
  text-decoration: none;
  max-height: calc(100vh - 60px - 250px);
  height: 100%;
  overflow-y: hidden;
}

.post-tags-move {
  border: none;
  background: none;
  position: relative;
  cursor: pointer;
  color: #606060;
}

.post-tags-move:hover {
  color: #404040;
}

.post-tags-move:first-child {
  padding-left: 20px;
}

.post-tags-move:first-child::after {
  width: 150%;
  content: '';
  display: block;
  position: absolute;
  left: 0px;
  top: 20px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 69%);
  height: 135%;
}

.post-tags-move:last-child {
  padding-left: 20px;
}

.post-tags-move:last-child::after {
  width: 150%;
  content: '';
  display: block;
  position: absolute;
  left: 0px;
  bottom: 20px;
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 69%);
  height: 135%;
}

.post-tags-move .icon {
  font-size: 20px;
}

.post-tag-name {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 24px;
  margin-right: 8px;
  margin-bottom: 12px;
  padding: 3px 10px;
  font-size: 14px;
  color: rgb(22, 24, 35);
  line-height: 1.5;
  border: 1px solid rgba(22, 24, 35, 0.2);
  border-radius: 12px;
  cursor: pointer;
}

/* .post-tag-name:not(:first-child) {
   display: inline-flex;
 } */

.post-tag-name.active {
  background: #606060;
}

.post-tag-name:hover {
  background: rgba(22, 24, 35, 0.03);
  transition: 0.5s;
}

.post-tag-name.active:hover {
  background: rgba(22, 24, 35, 0.5);
}

.post-tag-name .icon {
  width: 16;
  height: 16;
  font-size: 14px;
  color: #888;
}

.post-tag-name.active .icon,
.post-tag-name.active p {
  color: #fff;
}

.post-tag-name p {
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(22, 24, 35, 0.75);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-left: 6px;
}

.post-tag-name p .icon,
.post-tag-name.active p .icon {
  display: flex;
  align-items: center;
  color: #fe2c55;
}