.otp-input-icon {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  line-height: 1;
  border-radius: 50%;
  margin: 10px;
  background-color: #e5e5e5;
}

.otp-input {
  padding: 20px 0;
}

.use-recovery {
  cursor: pointer;
  text-decoration: underline;
}