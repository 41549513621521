.image-drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #999;
  border-radius: 10px;
  height: 250px;
  width: 100%;
}

.image-drop-container:hover {
  border: 2px dashed #777;
}

.image-upload-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
}

.image-upload-icon .icon {
  color: #666;
  font-size: 50px;
}

.image-drop-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-drop-message-text {
  text-align: center;
  color: #888;
  font-size: 20px;
}

.image-preview,
.image-drop-message {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;
}

.image-preview {
  object-fit: cover;
}

.image-file-input {
  display: none;
}