.dropzon-container {
  padding: 20px 0;
}

.dropzon-container p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
}

.drop-container:hover {
  border: 2px dashed #4aa1f3;
}

.upload-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
}

.upload-icon .icon {
  color: #999;
  font-size: 50px;
}

.drop-message {
  text-align: center;
  color: #aaa;
  font-family: Arial;
  font-size: 20px;
}

.file-display-container {
  margin-top: 20px;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.file-status-bar>div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}

.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.file-error-message {
  color: red;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  position: absolute;
}

.file-type-logo .icon {
  color: #aaa;
  font-size: 50px;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693D;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  position: absolute;
  top: 5px;
  right: 0;
  padding: 10px 0 10px 10px;
  line-height: 15px;
  cursor: pointer;
  background: #fff;
  color: red;
}

.modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.modal .overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .66);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.modal .modal-image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 80vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 999;
}

.close {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
  z-index: 999;
}

.close:hover {
  color: #e1e1e1;
}

.file-upload-btn {
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #4aa1f3;
  font-weight: bold;
  padding: 8px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}

.file-upload-btn:hover {
  background-color: #3899f5;
}

.file-input {
  display: none;
}

.upload-modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.upload-modal .overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .66);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.progress-container {
  background: transparent;
  width: 500px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 999;
}

.progress-container .upload-messages {
  display: flex;
  justify-content: center;
  color: #fff;
  padding-top: 100px;
  font-size: 20px;
  z-index: 999;
}

.progress {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #efefef;
  height: 20px;
  border-radius: 5px;
}

.progress-bar {
  position: absolute;
  background-color: #4aa1f3;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.progress-container .upload-messages.error {
  color: red;
}

@media screen and (max-width: 576px) {
  .progress-container {
    width: 280px;
    height: 200px;
  }

  .progress-container span {
    padding-top: 50px;
  }
}