.otp-input-icon {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  line-height: 1;
  border-radius: 50%;
  margin: 10px;
  background-color: #e5e5e5;
}

.otp-input {
  padding: 20px 0;
}

.btns {
  margin: 20px 0 0 0;
}

.btns .btn-recovery-cancel,
.btns .btn-recovery-submit {
  width: 130px;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.btns .btn-recovery-submit {
  background-color: #5995fd;
  border: 1px solid #4481eb;
  margin-right: 20px;
}

.btns .btn-recovery-submit:disabled {
  background-color: #5c81c0;
  cursor: not-allowed;
}

.btns .btn-recovery-cancel {
  background-color: #999;
  border: 1px solid #808080;
}

.btn-recovery-cancel:hover {
  transition: 0.5s ease-in-out;
  background-color: #8c8c8c;
}

.btn-recovery-submit:hover {
  transition: 0.5s ease-in-out;
  background-color: #4d84e2;
}