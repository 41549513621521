.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.scroll-to-top.icon {
  border: 2px solid rgb(22, 24, 35);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  color: rgb(22, 24, 35);
  cursor: pointer;
  animation: movebtn 5s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.scroll-to-top.icon:hover {
  animation: none;
  background: rgb(22, 24, 35);
  color: #fff;
  border: 2px solid rgb(22, 24, 35);
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(5px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
}