.image-grid {
  width: 100%;
}

.image-grid__container {
  position: relative;
}

.image-lightbox {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}

.image-lightbox__overlay {
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.333);
  overflow-y: hidden;
  transition: all 0.3 ease-in-out;
  z-index: 1999;
}

.image-lightbox__overlay.active {
  opacity: 1;
  pointer-events: all;
}

.image-lightbox__close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #fff;
  color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.image-lightbox__close:hover {
  background-color: #e5e5e5;
  transition: 0.5 ease-in-out;
}

.image-lightbox__container {
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}

.image-lightbox__container img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-lightbox__next-button .icon {
  color: #eee;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  right: 20px;
  top: 50%;
  margin-top: -24px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2000;
}

.image-lightbox__next-button .icon:hover {
  color: #aaa;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-out;
  z-index: 2000;
}

.image-lightbox__prev-button .icon {
  color: #eee;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  left: 20px;
  top: 50%;
  margin-top: -24px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 2000;

}

.image-lightbox__prev-button .icon:hover {
  color: #aaa;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-out;
  z-index: 2000;
}

.image-lightbox__action {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 3px 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2000;
}

.image-lightbox__action p {
  font-size: 20px;
  color: #333;
}

.image-lightbox__action .icon {
  margin-left: 10px;
  color: rgb(198, 57, 97);
  cursor: pointer;
}

.image-lightbox__action .icon:hover {
  color: rgb(214, 50, 96);
  transition: all 0.3s ease-out;
  z-index: 2000;
}

.image-grid__row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0;
}

.image-grid__col-12 {
  grid-column: span 12;
}

.image-grid__col-6 {
  grid-column: span 6;
}

.image-grid__col-4 {
  grid-column: span 4;
}

.image-grid__img-container {
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
  border: 2px solid #fff;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
}

.image-grid__img-background {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-grid__cover {
  background-color: #222;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 6px;
}

.image-grid__cover-text {
  right: 0;
  left: 0;
  bottom: 0;
  color: #f2f2f2;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}

.image-grid__cover-text p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-grid__slide {
  height: 0;
  bottom: 100%;
  transition: .5s ease;
  overflow: hidden;
}

.image-grid__img-container:hover .image-grid__slide {
  bottom: 0;
  height: auto;
}

.image-grid__img-container:hover .image-grid__animate-text {
  top: 62%
}

@media only screen and (max-width:576px) {
  .image-grid__img-container {
    font-size: 0.725rem;
  }

  .image-grid__cover-text {
    font-size: 1rem;
  }
}