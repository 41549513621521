.create-post {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 1rem;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  background-color: #fff;
}

.create-post .create-post-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-post .create-post-top a {
  text-decoration: none;
}

.create-post .create-post-top a img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.create-post .create-post-top .mock-input {
  height: 40px;
  width: 100%;
  border-radius: 20px;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: #666;
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.create-post .create-post-top .mock-input:hover {
  background-color: #e8e8e8;
}

.create-post .create-post-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
}

.create-post .create-post-bottom .create-post-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32%;
  padding: 0.25rem 0;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  cursor: pointer;
}

.create-post .create-post-bottom .create-post-icon:hover {
  background-color: #e8e8e8;
  transition: 0.5s;
}

.create-post .create-post-bottom .create-post-icon .icon {
  font-size: 1.5rem;
}

.create-post .create-post-bottom .create-post-icon span {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.create-post .create-post-bottom .create-post-icon .icon-video {
  color: #f02849;
}

.create-post .create-post-bottom .create-post-icon .icon-photo {
  color: #60b762;
}

.create-post .create-post-bottom .create-post-icon .icon-poll {
  color: #f7b03b;
}

@media screen and (min-width: 768px) {
  .create-post {
    margin: 1rem;
  }
}

@media only screen and (max-width:600px) {
  .create-post .create-post-bottom .create-post-icon span {
    display: none;
  }
}