.trade-popup {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.trade-popup .popup {
  height: 100%;
  width: 992px;
  text-align: left;
  padding: 0px;
}

.trade-popup .overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.trade-popup .content-wrapper {
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.trade-popup .content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.trade-popup .content .trade {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.trade-popup .content .trade .trade-entry,
.trade-popup .content .trade .trade-exit {
  width: 100%;
}

.trade-popup .content .image-upload-container {
  width: 100%;
  margin-bottom: 25px;
}

.trade-popup .content .input-wrapper {
  width: 100%;
  position: relative;
}

.trade-popup .content .input-wrapper label {
  display: inline-block;
  align-items: start;
  color: #212529;
  font-size: 14px;
  margin-bottom: 8px;
}

.trade-popup .content .input-wrapper .invalid-feedback {
  position: absolute;
  bottom: -16px;
  left: 6px;
  font-size: 13px;
  color: #dc3545;
}

.trade-popup .content .input-container,
.trade-popup .content .select-container {
  width: 100%;
  margin-bottom: 16px;
}

.trade-popup .content .input-group,
.trade-popup .content .select-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
}

.trade-popup .content .input-group input,
.trade-popup .content .input-group .react-datepicker__input-container input,
.trade-popup .content .input-group .react-datepicker-time__input input {
  width: 100%;
  height: 36px;
  padding: 8px 12px;
  color: #4b4b4b;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.trade-popup .content .input-group input::placeholder {
  color: rgb(133, 133, 133);
}

.trade-popup .content .image-upload-container {
  margin-bottom: 25px
}

.trade-popup .content .image-upload-container .image-drop-container {
  height: 150px;
}

.trade-popup .content .btn-update-container {
  width: 100%;
}

.trade-popup .content .btn-update-container .btn-create,
.trade-popup .content .btn-update-container .btn-update,
.trade-popup .content .btn-update-container .btn-cancel {
  padding: 10px 20px;
  float: right;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 5px;
  border-color: transparent;
}

.trade-popup .content .btn-update-container .btn-create {
  background-color: #28a745;
  color: rgba(0, 0, 0, 0.7);
}

.trade-popup .content .btn-update-container .btn-create:hover {
  background-color: #238d3b;
  transition: background-color 0.3s ease-out;
}

.trade-popup .content .btn-update-container .btn-create:disabled {
  background-color: #6dd886;
  cursor: not-allowed;
}


.trade-popup .content .btn-update-container .btn-update {
  background-color: #ffe08a;
  color: rgba(0, 0, 0, 0.7);
}

.trade-popup .content .btn-update-container .btn-update:disabled {
  background-color: #e4ce8f;
  cursor: not-allowed;
}

.trade-popup .content .btn-update-container .btn-update:hover {
  background-color: #ffdc7d;
  transition: background-color 0.3s ease-out;
}

.trade-popup .content .btn-update-container .btn-success {
  background-color: #48c78e;
  color: #fff;
}

.trade-popup .content .btn-update-container .btn-success:hover {
  background-color: #3ec487;
  transition: background-color 0.3s ease-out;
}

.trade-popup .content .btn-update-container .btn-cancel {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.7);
}

.trade-popup .content .btn-update-container .btn-cancel:hover {
  background-color: #eee;
  transition: background-color 0.3s ease-out;
}

@media screen and (max-width: 1200px) {
  .trade-popup .popup {
    width: 778px;
  }

  .trade-popup .content .input-group,
  .trade-popup .content .select-group {
    flex-direction: column;
    gap: 0px;
  }

  .trade-popup .content .input-group .mt-custom,
  .trade-popup .content .select-group .mt-custom {
    margin-top: 16px;
  }
}

@media screen and (max-width: 992px) {
  .trade-popup .popup {
    width: 600px;
  }

  .trade-popup .content .trade {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .trade-popup .content .input-group,
  .trade-popup .content .select-group {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .trade-popup .popup {
    width: 500px;
  }

  .trade-popup .content .btn-update-container {
    padding-top: 0px;
  }
}

@media screen and (max-width: 500px) {
  .trade-popup .popup {
    width: 380px;
  }

  .trade-popup .content-wrapper {
    padding: 20px;
  }

  .trade-popup .content .select-group .input-wrapper:nth-child(2) {
    margin-top: 8px;
  }
}