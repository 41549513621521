@import "./../../../styles/variables.scss";
@import "./../../../styles/responsive.scss";

.trade-position-size {
  width: 100%;

  .content {
    min-height: 500px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);

    .header {
      h2 {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.6;
      }
    }

    .calculation {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      gap: 20px;

      @include md {
        flex-direction: column;
      }

      .left {
        flex: 2;

        .form-control {
          padding: 8px 0;

          label {
            font-size: 14px;
            color: #555;
          }

          input {
            width: 100%;
            padding: 12px 20px;
            margin: 8px 0;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }
        }
      }

      .right {
        flex: 3;
        display: flex;
        flex-direction: column;
        align-items: center;

        .result {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 12px 0;
          margin: 8px 0;

          p {
            font-size: 16px;
            color: #555;
          }

          h3 {
            padding: 8px;
            font-size: 20px;
          }
        }
      }
    }
  }
}