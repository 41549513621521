.blog-comment-reply {
  padding-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;

}

.blog-comment-reply .user-avartar {
  padding-bottom: 1rem;
  margin-right: 0.5rem;
}

.blog-comment-reply .user-avartar img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}