.input-emoji {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.input-emoji--input {
  width: 100%;
  color: #4b4b4b;
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  max-height: 100px;
  min-height: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 9px 12px 11px;
  border-radius: 21px;
  border: 1px solid rgb(234, 234, 234)
}

.input-emoji--input:focus {
  outline: none;
  border: 1px solid #4b4b4b;
  outline: none;
}

.input-emoji--input:focus::placeholder {
  color: #4b4b4b;
}

.input-emoji--button {
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.input-emoji--button-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #858585;
}

.input-emoji--button-icon.active,
.input-emoji--button-icon:hover {
  color: #128b7e;
}


.input-emoji-picker--container {
  position: absolute;
  top: 0;
  width: 100%;
}


.input-emoji-picker--wrapper {
  position: absolute;
  /*emoji-mart v3*/
  /*bottom: 0;*/
  /*emoji-mart v5*/
  bottom: 16px;
  right: 0;
  /*emoji-mart v3*/
  /* height: 357px; 
  overflow: hidden;
  z-index: 10;
  width: 353px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 5px;
  */
}

/*emoji-mart v5*/
.input-emoji-picker--wrapper em-emoji-picker {
  height: 260px;
}