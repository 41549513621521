.toast {
  width: 100%;
  width: 380px;
  height: 80px;
  padding: 20px;
  background-color: #fff;
  border-radius: 7px;
  display: grid;
  grid-template-columns: 1.3fr 6fr 0.5fr;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
  position: relative;
}

.toast:not(:last-child) {
  margin-bottom: 15px;
}

.toast.top-right {
  top: 12px;
  right: 12px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.toast.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.toast.top-left {
  top: 12px;
  left: 12px;
  transition: transform .6s ease-in;
  animation: toast-in-left .7s;
}

.toast.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform .6s ease-in;
  animation: toast-in-left .7s;
}

.toast.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform .6s ease-in;
  animation: toast-in-center .7s;
}

.toast-success {
  border-left: 8px solid #47f764;
}

.toast-success .icon {
  color: #47f764;
}

.toast-container-top .icon {
  font-size: 35px;
}

.toast-error {
  border-left: 8px solid #ff355b;
}

.toast-error .icon {
  color: #ff355b;
}

.toast-info {
  border-left: 8px solid #2f86eb;
}

.toast-info .icon {
  color: #2f86eb;
}

.toast-warning {
  border-left: 8px solid #ffc021;
}

.toast-warning .icon {
  color: #ffc021;
}

.toast-container-top,
.toast-container-buttom {
  align-self: center;
}

.toast-container-buttom p:first-child {
  font-size: 16px;
  color: #101020;
  font-weight: 600;
}

.toast-container-buttom p:last-child {
  font-size: 14px;
  color: #656565;
  font-weight: 400;
}

.toast .toast-btn {
  align-self: flex-start;
  background-color: transparent;
  border: none;
  font-size: 25px;
  line-height: 0;
  cursor: pointer;
  color: #656565;
}

.toast-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  right: 0;
  background-color: #fff;
}

.toast-progress::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  right: 0;
}

.toast-progress.success::before {
  background-color: #54A668;
}

.toast-progress.error::before {
  background-color: #DF6962;
}

.toast-progress.info::before {
  background-color: #71C3DD;
}

.toast-progress.warning::before {
  background-color: #F3CC6B;
}

/* Use in react style*/
/* .toast-progress.active::before {
  animation: progress 5s linear forwards;
} */

@keyframes progress {
  100% {
    right: 100%;
  }
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);

  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);

  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-center {
  from {
    transform: translate(0, 100%);

  }

  to {
    transform: translate(-50%, -50%);
  }
}