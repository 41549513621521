.link-preview {
  max-width: 640px;
  background-color: rgb(255, 255, 255);
  color: rgb(24, 25, 25);
  display: flex;
  opacity: 1;
  position: relative;
  transition-duration: 0.35s;
  transition-timing-function: ease-in-out;
  flex-direction: row;
  height: 127px;
  transition-property: background, border-color, height;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(225, 232, 237);
  overflow: hidden;
  text-decoration: none;
  -webkit-tap-highlight-color: rgb(245, 248, 250);
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 8px 0px;
}

.link-preview.large {
  flex-direction: column;
  height: 382px;
}

.link-preview__media {
  height: auto;
  position: relative;
  overflow: hidden;
  background: center center / cover no-repeat rgb(225, 232, 237);
  flex: 0 0 125px;
  transition: flex-basis 0.25s ease-in-out 0s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-preview__media.large {
  flex: 1 1 0%;
}

.link-preview__media img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link-preview__content {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 10px 15px;
  min-width: 0px;
  box-sizing: border-box;
}

.link-preview__content.large {
  flex: 0 0 125px;
}

.link-preview__content-header {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
  -webkit-box-flex: 1.2;
  flex-grow: 1.2;
}

.link-preview__content-content {
  text-align: left;
  font-size: 14px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin: auto 0px;
  line-height: 18px;
}

.link-preview__content-footer {
  text-align: left;
  font-size: 12px;
  margin: 0px;
  -webkit-box-flex: 0;
  flex-grow: 0;
}

@media screen and (max-width: 576px) {

  .link-preview.large {
    height: 200px;
  }

  .link-preview__media,
  .link-preview__media.large {
    flex: 1;
  }

  .link-preview__content {
    display: none;
  }

  .link-preview__content.large {
    display: flex;
    flex: 0 0 80px;
  }

  .link-preview__content.large .link-preview__content-content,
  .link-preview__content.large .link-preview__content-footer {
    display: none;
  }
}