.confirm-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirm-popup .icon-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid #facea8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px 0 20px 0;
}

.confirm-popup .icon {
  font-size: 46px;
  color: #f8bb86;
}

.confirm-popup h2 {
  font-size: 30px;
  color: #444950;
  font-weight: 600;
  margin-top: 5px;
}

.confirm-popup h4 {
  padding-top: 16px;
  font-size: 24px;
  font-weight: 400;
  color: #404040
}

.confirm-popup .btns {
  margin: 40px 0 0 0;
}

.confirm-popup .btns .btn-cancel,
.confirm-popup .btns .btn-delete {
  background-color: #999;
  color: #fff;
  font-size: 18px;
  padding: 10px 13px;
  border: 1px solid #808080;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-popup .btns .btn-delete {
  background-color: #ff3333;
  margin-right: 20px;
  border: 1px solid #cc0000;
}

.confirm-popup .btn-cancel:hover {
  transition: 0.5s ease-in-out;
  background-color: #8c8c8c;
}

.confirm-popup .btn-delete:hover {
  transition: 0.5s ease-in-out;
  background-color: #e60000;
}