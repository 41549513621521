.wallet-popup {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wallet-popup .content {
  width: 100%;
  padding: 32px 16px 16px;
}

.wallet-popup .content .input-container,
.wallet-popup .content .datetime-container {
  width: 100%;
  margin-bottom: 24px;
}

.wallet-popup .content .input-wrapper {
  width: 100%;
  position: relative;
}

.wallet-popup .content .input-wrapper .invalid-feedback {
  position: absolute;
  bottom: -16px;
  left: 6px;
  font-size: 13px;
  color: #dc3545;
}

.wallet-popup .content .input-container input,
.wallet-popup .content .datetime-container .react-datepicker__input-container input,
.wallet-popup .content .datetime-container .react-datepicker-time__input input {
  width: 100%;
  height: 36px;
  padding: 8px 12px;
  color: #4b4b4b;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-color: hsl(0, 0%, 80%);
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
}

.wallet-popup .content .input-container input::placeholder {
  color: rgb(133, 133, 133);
}

.wallet-popup .content .btn-container {
  width: 100%;
  height: 20px;
}

.wallet-popup .content .btn-container .btn-create,
.wallet-popup .content .btn-container .btn-update,
.wallet-popup .content .btn-container .btn-cancel {
  padding: 10px 20px;
  float: right;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 5px;
  border-color: transparent;
}

.wallet-popup .content .btn-container .btn-create {
  background-color: #28a745;
  color: rgba(0, 0, 0, 0.7);
}

.wallet-popup .content .btn-container .btn-create:hover {
  background-color: #238d3b;
  transition: background-color 0.3s ease-out;
}

.wallet-popup .content .btn-container .btn-create:disabled {
  background-color: #6dd886;
  cursor: not-allowed;
}


.wallet-popup .content .btn-container .btn-update {
  background-color: #ffe08a;
  color: rgba(0, 0, 0, 0.7);
}

.wallet-popup .content .btn-container .btn-update:disabled {
  background-color: #e4ce8f;
  cursor: not-allowed;
}

.wallet-popup .content .btn-container .btn-update:hover {
  background-color: #ffdc7d;
  transition: background-color 0.3s ease-out;
}

.wallet-popup .content .btn-container .btn-success {
  background-color: #48c78e;
  color: #fff;
}

.wallet-popup .content .btn-container .btn-success:hover {
  background-color: #3ec487;
  transition: background-color 0.3s ease-out;
}

.wallet-popup .content .btn-container .btn-cancel {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.7);
}

.wallet-popup .content .btn-container .btn-cancel:hover {
  background-color: #eee;
  transition: background-color 0.3s ease-out;
}

@media screen and (max-width: 768px) {
  .wallet-popup .content .btn-container {
    padding-top: 25px;
  }
}