.activity-embed-preview {
  width: 100%;
  margin: 1rem 0;
  position: relative;
  border: 1px solid transparent;
}

.activity-embed-preview:hover {
  border: 1px solid #f5f5f5;
  transition: all 0.5 ease-in-out;
}

.activity-embed-preview__close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.activity-embed-preview__close:hover {
  background-color: #f5f5f5;
  transition: 0.5 ease-in-out;
}

.activity-embed-preview__body {
  padding: 1rem 1rem 1rem 1rem;
  position: relative;
}

.activity-embed-preview__body .activity-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.activity-embed-preview__body .activity-list .activity-list-item {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
}

.activity-embed-preview__body .activity-list .activity-list-item input {
  width: 100%;
  margin-right: 8px;
  padding: 8px 12px;
  color: #4b4b4b;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 4px;
  border: 1px solid rgb(234, 234, 234)
}

.activity-embed-preview__body .activity-list .activity-list-item .input-activity {
  width: 60%;
  margin-right: 8px;
  position: relative;
}

.activity-embed-preview__body .activity-list .activity-list-item .input-score {
  width: 30%;
  margin-right: 8px;
  position: relative;
}

.activity-embed-preview__body .activity-list .activity-list-item .activity-invalid-feedback {
  position: absolute;
  bottom: -16px;
  left: 6px;
  padding-left: 5px;
  font-size: 14px;
  color: #dc3545;
}

.activity-embed-preview__body .activity-list .activity-list-item button {
  padding: 8px 12px;
  font-size: 16px;
  color: #fff;
  background-color: #dc3545;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #dc3545;
  cursor: pointer;
}

.activity-embed-preview__body .activity-list .activity-list-item button:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.activity-embed-preview__body .activity-list .btn-add {
  margin-top: 16px;
  padding: 8px 12px;
  font-size: 16px;
  color: #fff;
  background-color: #6c757d;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #6c757d;
  cursor: pointer;
}

.activity-embed-preview__body .activity-list .btn-add:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.activity-embed-preview__activity {
  padding: 1rem 1rem 1rem;
}

.activity-btn-submit {
  width: 100%;
  margin-top: 16px;
  padding: 8px 12px;
  font-size: 16px;
  color: #fff;
  background-color: #28a745;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #28a745;
  cursor: pointer;
}

.activity-btn-submit:hover {
  background-color: #218838;
  border-color: #1e7e34;
}