@import "./../../styles/variables.scss";
@import "./../../styles/responsive.scss";

.trade-menu {
  width: 100%;

  .logo-item {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 20px;

    @include lg {
      padding: 10px;
      justify-content: center;
    }

    .logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 10px;

      img {
        height: 36px;
        width: auto;
      }

      .icon {
        font-size: 24px;
        color: $green-color;
      }

      span {
        font-size: 20px;
        font-weight: 600;
        color: $main-color;

        @include lg {
          display: none;
        }
      }
    }
  }

  .menu-item {
    width: 100%;
    margin-bottom: 4px;
    position: relative;

    &:has(.menu-item-link.active)::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 2px;
      height: 100%;
      background-color: $green-color;
    }

    .menu-item-link {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 10px 10px 20px;
      color: $main-color;
      text-decoration: none;

      @include lg {
        padding: 10px;
        justify-content: center;
      }

      &.active {
        color: $green-color;
      }

      .icon {
        font-size: 24px;
      }

      &:hover {
        background-color: $gray-100;
      }

      .menu-item-title {
        @include lg {
          display: none;
        }
      }
    }
  }
}