// Background
$main-bg: #fff;
$soft-bg: #fafafa;
$dark-bg: #384256;

// Text
$main-color: #181823;
$soft-color: #6e6e6e;
$dark-color: #2a3447;

// Color
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$green-color:#35c759;
$green-lighten-color:#84de9a;
$green-darken-color:#207635;
$green-brighten-color:#68fa8c;
$green-saturate-color:#1ce04c;
$green-desaturate-color:#4eae66;

$blue-color:#1c89ff;
$blue-lighten-color:#82beff;
$blue-darken-color:#0057b5;
$blue-brighten-color:#4fbcff;
$blue-saturate-color:#1c89ff;
$blue-desaturate-color:#338ae8;

$seablue-color:#2274a5;
$seablue-lighten-color:#52a8db;
$seablue-darken-color:#113950;
$seablue-brighten-color:#55a7d8;
$seablue-saturate-color:#0e79b9;
$seablue-desaturate-color:#366f91;

$red-color:#f75361;
$red-lighten-color:#fcb4bb;
$red-darken-color:#da0a1c;
$red-brighten-color:#ff8694;
$red-saturate-color:#ff4b5a;
$red-desaturate-color:#e56570;

$yellow-color:#f1c40f;
$yellow-lighten-color:#f7dc6f;
$yellow-darken-color:#917608;
$yellow-brighten-color:#fff742;
$yellow-saturate-color:#ffcc01;
$yellow-desaturate-color:#d8b528;

$orange-color:#f75c03;
$orange-lighten-color:#fd9b63;
$orange-darken-color:#923602;
$orange-brighten-color:#ff8f36;
$orange-saturate-color:#fa5b00;
$orange-desaturate-color:#de631c;

$purple-color:#a476f0;
$purple-lighten-color:#e1d2fa;
$purple-darken-color:#671ae6;
$purple-brighten-color:#d7a9ff;
$purple-saturate-color:#a067ff;
$purple-desaturate-color:#a885e1;

$gold-color:#f9bf57;
$gold-lighten-color:#fde5b9;
$gold-darken-color:#e29408;
$gold-brighten-color:#fff28a;
$gold-saturate-color:#ffc151;
$gold-desaturate-color:#e8ba68;

$pink-color:#d90368;
$pink-lighten-color:#fc469c;
$pink-darken-color:#740238;
$pink-brighten-color:#ff369b;
$pink-saturate-color:#dc0068;
$pink-desaturate-color:#c31969;