.input-blog-comment-container {
  display: 'flex';
  flex: 1;
  position: 'relative';
  padding-bottom: 1rem;
}


.input-blog-comment-container .react-emoji-picker--wrapper {
  width: 360px;
}