.image-grid {
  width: 100%;
}

.image-grid__row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0;
}

.image-grid__col-12 {
  grid-column: span 12;
}

.image-grid__col-6 {
  grid-column: span 6;
}

.image-grid__col-4 {
  grid-column: span 4;
}

.image-grid__img-container {
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
  border: 2px solid #fff;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
}

.image-grid__img-background {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-grid__cover {
  background-color: #222;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 6px;
}

.image-grid__cover-text {
  right: 0;
  left: 0;
  bottom: 0;
  color: #f2f2f2;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}

.image-grid__cover-text p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-grid__slide {
  height: 0;
  bottom: 100%;
  transition: .5s ease;
  overflow: hidden;
}

.image-grid__img-container:hover .image-grid__slide {
  bottom: 0;
  height: auto;
}

.image-grid__img-container:hover .image-grid__animate-text {
  top: 62%
}

@media only screen and (max-width:576px) {
  .image-grid__img-container {
    font-size: 0.725rem;
  }

  .image-grid__cover-text {
    font-size: 1rem;
  }
}