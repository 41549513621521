.popup {
  user-select: none;
  width: 380px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 40px 20px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 1;
  pointer-events: none;
  transition: all 0.3 ease-in-out;
}

.popup.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup.active {
  opacity: 1;
  pointer-events: all;
}

.overlay {
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
  transition: all 0.3 ease-in-out;
  z-index: 9998;
}

.overlay.active {
  opacity: 1;
  pointer-events: all;
}