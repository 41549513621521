.video-embed-preview {
  width: 100%;
  margin: 1rem 0;
  position: relative;
  border: 1px solid transparent;
}

.video-embed-preview:hover {
  border: 1px solid #f5f5f5;
  transition: all 0.5 ease-in-out;
}

.video-embed-preview__close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.video-embed-preview__close:hover {
  background-color: #f5f5f5;
  transition: 0.5 ease-in-out;
}

.video-embed-preview__input {
  padding: 1rem 1rem 1rem 1rem;
  position: relative;
}

.video-embed-preview__input div {
  display: flex;
  flex-direction: row;
}

.video-embed-preview__input input:first-child {
  width: 100%;
  color: #4b4b4b;
  line-height: 20px;
  font-size: 15px;
  font-weight: 400;
  max-height: 100px;
  min-height: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 9px 12px 11px;
  border-radius: 5px;
  border: 1px solid rgb(234, 234, 234)
}

.video-embed-preview__input input:focus {
  outline: none;
  border: 1px solid #4b4b4b;
  outline: none;
}

.video-embed-preview__input input:focus::placeholder {
  color: #4b4b4b;
}

.video-embed-preview__input label {
  margin: 0 1rem;
  color: #4b4b4b;
}

.video-embed-preview__input label input {
  border: 1px solid rgb(234, 234, 234);
}

.video-embed-preview__input span {
  color: hsl(348, 100%, 61%);
  position: absolute;
  font-size: 14px;
  left: 20px;
  bottom: 0;
}

.video-embed-preview__video {
  padding: 1rem 1rem 1rem;
}