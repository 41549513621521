blockquote {
  color: #333;
  padding: 1.2em 30px 1.2em 50px;
  border-left: 8px solid #1da1f2;
  line-height: 1.6;
  position: relative;
  background: #f8f8f8;
}

blockquote:before {
  content: "“";
  color: #9cd7f7;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -20px;
}

code {
  color: #333;
  padding: 1.2em;
  line-height: 1.6;
  border-radius: 6px;
  background: #f8f8f8;
}

.emoji-container {
  position: relative;
}

.emoji-piker {
  position: absolute;
  top: 25px;
  right: 290px;
  width: 100%;
  z-index: 20;
}

/*emoji-mart v3*/
/* .editor .emoji-mart-scroll {
  height: 100px !important;
} */

/*emoji-mart v5*/
.editor .emoji-piker em-emoji-picker {
  height: 260px;
}

.image-upload-input {
  display: none;
}

@media screen and (max-width: 576px) {
  .emoji-piker {
    right: 100px;
  }
}

@media screen and (max-width: 500px) {
  .emoji-piker {
    right: 120px;
  }
}

@media screen and (max-width: 420px) {
  .emoji-piker {
    right: 205px;
  }
}