.blog-content__left-container {
  padding: 10px 20px 10px 0;
}

.blog-content__left-container h3 {
  color: #333;
  font-size: 1.375rem;
  margin-bottom: 0.5rem;
}

.blog-content__left-container .menu-list {
  list-style: none;
}

.blog-content__left-container .menu-list-item {
  margin-bottom: 0.5rem;
}

.blog-content__left-container .menu-list-item a {
  border-radius: 0.75rem;
  text-decoration: none;
}

.blog-content__left-container .menu-list-item a .item-wrapper {
  min-height: 38px;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
}

.blog-content__left-container .menu-list-item a.active .item-wrapper {
  background-color: #e9e9e9;
  transition: 0.5s;
}


.blog-content__left-container .menu-list-item a .item-wrapper:hover {
  background-color: #f5f5f5;
  transition: 0.5s;
}

.blog-content__left-container .menu-list-item a .item-wrapper .icon {
  font-size: 24px;
  color: #222;
}

.blog-content__left-container .menu-list-item a .item-wrapper span {
  font-size: 18px;
  margin-left: 10px;
  color: #222;
  font-weight: 600;
}