.profile-page {
  display: flex;
  margin-top: 60px;
}

.profile-page-container {
  background-color: rgb(238, 238, 238);
  width: 100%;
}

.profile-page-wrapper {
  margin: 0px auto;
  max-width: 1140px;
  padding: 20px;
}

.profile-page-content {
  box-shadow: rgb(0 0 0 / 6%) 0px 2px 8px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  min-height: calc(100vh - 60px - 2 * 20px);
}

.profile-page-content-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.profile-page-content-left {
  flex: 3;
  border: 1px solid #eee;
  min-height: calc(100vh - 60px - 2 * 20px);
}

.profile {
  width: 100%;
  padding: 30px 25px;
  text-align: center;
  color: #333;
}

.profile img {
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile h4 {
  margin-bottom: 10px;
}

.profile-menu {
  display: flex;
  flex-direction: column;
}

.profile-menu .tab:first-child {
  border-top: 1px solid #ddd;
}

.profile-menu .tab {
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  color: #333;
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.profile-menu .tab.active {
  background-color: #007bff;
  color: #fff;
}

.profile-menu .tab span {
  margin-left: 12px;
}

.profile-page-content-right {
  flex: 9;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  width: 100%;
}

.profile-tab .title {
  color: #212529;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.75rem;
}

.profile-tab .description {
  color: #212529;
  font-size: 1rem;
}

.profile-tab .content {
  margin-top: 20px;
  /* margin-bottom: 30px; */
  background-color: #f7f8f9;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

.profile-tab .content p.bold {
  font-weight: 600;
  margin-bottom: 10px;
}

.profile-tab .content .app-google-authenticator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-tab .content .app-google-authenticator a img {
  width: 80px;
  height: auto;
}

.profile-tab .content .app-google-authenticator a:last-child img {
  width: 100px;
}

/* account */
.profile-tab .account {
  display: flex;
  flex-direction: column;
}

.profile-tab .account .profile-upload {
  width: 150px;
  height: 150px;
  border: 2px dashed #999;
  border-radius: 50%;
}

.profile-tab .account .profile-upload:hover {
  border: 2px dashed #777;
}

.profile-tab .account .profile-upload .icon-wrapper {
  margin-bottom: 5px;
}

.profile-tab .account .profile-upload .icon-wrapper .icon {
  color: #666;
  font-size: 36px;
}

.profile-tab .account .profile-upload .profile-upload-preview {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.profile-tab .account .profile-upload .profile-upload-text {
  text-align: center;
  color: #888;
  font-size: 16px;
}

.profile-tab .account .form-user-account {
  margin-top: 20px;
  padding: 0;
}

.profile-tab .account .form-control {
  display: flex;
  flex-direction: column;
  max-width: 380px;
  padding: 10px 0;
}

.profile-tab .account .form-control label {
  font-size: 14px;
  color: #777;
  margin-bottom: 5px;
}

.profile-tab .account .form-control input {
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ccc;
}

.profile-tab .account .form-control input:focus {
  border: 1px solid #999;
}

.profile-tab .account .account-bottom {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  margin: 20px 0;
}

.profile-tab .account-bottom .account-btn {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  background-color: rgba(37, 47, 63, 1);
  border: none;
  outline: none;
  border-radius: 0.375rem;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}

.profile-tab .account-bottom .account-btn:hover {
  background-color: rgba(55, 65, 81, 1);
  transition: 0.3s ease-in-out;
}

/* security */
.profile-tab .security {
  margin-bottom: 20px;
}

.profile-tab .security .security-btn,
.profile-tab .application .application-btn,
.profile-tab .application .otp-container .otp-btn {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  background-color: rgba(37, 47, 63, 1);
  border: none;
  outline: none;
  border-radius: 0.375rem;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}

.profile-tab .security .security-btn:hover,
.profile-tab .application .application-btn:hover,
.profile-tab .application .application-btn.disable:hover,
.profile-tab .application .otp-container .otp-btn:hover {
  background-color: rgba(55, 65, 81, 1);
  transition: 0.3s ease-in-out;
}

/* application */
.profile-tab .application {
  margin-top: 10px;
  margin-bottom: 20px;
}

.profile-tab .application .application-btn.disable {
  background-color: rgb(107 114 128);
}

.profile-tab .application .otp-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.profile-tab .application .otp-container .qrcode {
  max-width: 250px;
  padding: 10px 20px;
}

.profile-tab .application .otp-container .otp-form-control .otp-secret,
.profile-tab .application .otp-container .otp-form-control .otp-recovery {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
  border: none;
  outline: none;
  background-color: #f2f2f2;
  resize: none;
  position: relative;
}

.profile-tab .application .otp-container .otp-secret:focus {
  border: none;
  outline: none;
}

.profile-tab .application .otp-container .otp-form-control {
  width: 100%;
  position: relative;
}

.profile-tab .application .otp-container .otp-form-control .otp-title {
  text-align: center;
  padding: 5px;
  font-weight: 600;
  color: #333;
}

.profile-tab .application .otp-container .otp-form-control .otp-notice {
  display: none;
}

.profile-tab .application .otp-container .otp-form-control:hover .otp-notice {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  padding: 2px 10px;
  text-align: center;
  font-size: 1rem;
  color: #333;
  background-color: #ffe08a;
  border-radius: 20px;
  transition: all 0.5s;
  z-index: 1;
}

.profile-tab .application .otp-container .otp-input {
  padding: 10px 5px;
}

.profile-tab .application .otp-container .otp-submit {
  padding: 10px 5px;
}

/* sessions */
.profile-tab .sessions {
  margin-top: 20px;
}

.profile-tab .session {
  display: flex;
  flex-direction: column;
}

.profile-tab .session:not(:first-child) {
  padding: 20px 0;
  border-top: 1px solid #ddd;
}

.profile-tab .session-top {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.profile-tab .session-top .icon {
  color: rgb(107 114 128);
  font-size: 24px;
}

.profile-tab .session-top .session-detail {
  color: rgb(75 85 99);
  margin-left: 10px;
}

.profile-tab .session-top .session-detail p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.profile-tab .session-top .session-detail .session-ip {
  font-size: 14px;
  line-height: 18px;
}

.profile-tab .session-top .session-detail .session-current {
  color: rgb(34 197 94);
  margin-left: 5px;
}

.profile-tab .session-bottom {
  margin: 20px 0;
}

.profile-tab .session-bottom .session-btn {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  background-color: rgba(37, 47, 63, 1);
  border: none;
  outline: none;
  border-radius: 0.375rem;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}

.profile-tab .session-bottom .session-btn:hover {
  background-color: rgba(55, 65, 81, 1);
  transition: 0.3s ease-in-out;
}

.profile-tab .session-bottom .session-btn:disabled {
  background-color: rgba(55, 65, 81, 1);
  cursor: not-allowed;
}

/* delete-account */
.profile-tab .delete-account {
  margin-top: 20px;
  margin-bottom: 20px;
}

.profile-tab .delete-account .delete-account-btn {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  background-color: rgba(220, 38, 38, 1);
  border: none;
  outline: none;
  border-radius: 0.375rem;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}

.profile-tab .delete-account .delete-account-btn:hover {
  background-color: rgba(220, 38, 38, 0.9);
  transition: 0.3s ease-in-out;
}

@media only screen and (max-width:768px) {
  .profile-page-content-right {
    margin-left: 10px;
    margin-right: 10px;
  }

  .profile-tab .content {
    padding: 10px;
  }
}

@media only screen and (min-width:501px) and (max-width:768px) {
  .profile-menu .tab .icon-ex {
    font-size: 20px;
  }
}

@media only screen and (max-width:500px) {
  .profile-page-content-left {
    flex: 1;
  }

  .profile-page-content-right {
    flex: 5;
  }

  .profile h4,
  .profile-menu .tab span {
    display: none;
  }

  .profile {
    width: 100%;
    padding: 30px 10px;
  }

  .profile img {
    width: 35px;
  }
}