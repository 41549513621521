.infinite-loading {
  display: flex;
  justify-content: center;
  padding: 16px 0;
}

.infinite-loading.absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}