 .post {
   margin-top: 1rem;
   margin-bottom: 1rem;
   border: 1px solid #c8c8c8;
   border-radius: 10px;
   background-color: #fff;
   /* border-top: 8px solid #e8e8e8; */
 }

 .post .post-header {
   display: flex;
   justify-content: space-between;
   flex-direction: row;
   padding: 1rem 1rem 0.5rem;
 }

 .post .post-header .post-header-left {
   display: flex;
   flex-direction: row;
 }

 .post .post-header .post-header-left img {
   width: 40px;
   height: 40px;
   border-radius: 50%;
 }

 .post .post-header .post-header-left .post-user-info {
   margin-left: 1rem;
 }

 .post .post-header .post-header-left .post-user-info a {
   text-decoration: none;
   font-size: 1rem;
 }

 .post .post-header .post-header-left .post-user-info a:hover {
   text-decoration: underline;
 }

 .post .post-header .post-header-left .post-user-info .post-username a {
   font-weight: 600;
 }

 .post .post-header .post-header-left .post-user-info .post-username a:first-of-type {
   color: #555;
 }

 .post .post-header .post-header-left .post-user-info .post-username a:last-of-type {
   color: #2f55d4;
 }

 .post .post-header .post-header-left .post-user-info .post-date a {
   color: #555;
 }

 .post .post-header .post-header-left .post-user-info .post-date .icon {
   font-size: 0.725rem;
   color: #555;
   margin-left: 0.5rem;
 }

 .post .post-header .post-header-right {
   display: flex;
   flex-direction: row;
   align-items: center;
   position: relative;
 }

 .post .post-header .post-header-right .right-icon {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
 }

 .post .post-header .post-header-right .right-icon:not(:first-child) {
   margin-left: 0.5rem;
 }

 .post .post-header .post-header-right .right-icon:hover {
   background-color: #e9e9e9;
 }

 .post .post-header .post-header-right .right-icon .icon {
   font-size: 1.5rem;
 }

 .post .post-header .post-header-right .right-icon .icon.pin {
   color: #28a745;
 }

 .post .post-header .blog-actions {
   position: absolute;
   background-color: #fff;
   white-space: nowrap;
   margin-right: 16px;
   border-radius: 8px;
   z-index: 1;
   top: 130%;
   right: 20%;
   transform: translateX(30%);
   transition: opacity 0.3s;
   -webkit-box-shadow: 0px 0px 5px 2px rgba(232, 232, 232, 1);
   -moz-box-shadow: 0px 0px 5px 2px rgba(232, 232, 232, 1);
   box-shadow: 0px 0px 5px 2px rgba(232, 232, 232, 1);
 }

 .post .post-header .blog-actions::after {
   content: "";
   position: absolute;
   bottom: 100%;
   right: 8%;
   margin-left: -8px;
   border-width: 8px;
   border-style: solid;
   border-color: transparent transparent #e8e8e8 transparent;
 }

 .post .post-header .blog-actions-list {
   display: flex;
   flex-direction: column;
   font-size: 16px;
   color: #333;
 }

 .post .post-header .blog-actions-list .icon {
   font-size: 20px;
   margin-right: 8px;
 }

 .post .post-header .blog-actions-list-item {
   cursor: pointer;
   padding: 10px 30px 10px 20px;
   display: flex;
   align-items: center;
 }

 .post .post-header .blog-actions-list-item:first-child {
   margin-top: 8px;
 }

 .post .post-header .blog-actions-list-item:last-child {
   margin-bottom: 8px;
   border-top: 1px solid #e6e6e6;
   color: crimson;
 }

 .post .post-header .blog-actions-list-item:hover {
   background-color: #f8f8f8;
   transition: background-color 0.3s;
 }

 .post .post-content {
   padding: 0.825rem 1rem;
 }

 .post .blog-tags {
   padding-top: 1rem;
 }

 .post .blog-tags-item {
   display: inline-flex;
   align-items: center;
   padding: 3px 10px;
   color: #888;
   border: 1px solid rgba(22, 24, 35, 0.3);
   border-radius: 50px;
   cursor: pointer;
   transition: background 0.2s ease;
 }

 .post .blog-tags-item:not(:first-child) {
   margin-left: 5px;
 }

 .post .blog-tags-item.active {
   background: rgba(22, 24, 35, 0.1);
 }

 .post .blog-tags-item:hover {
   background: rgba(22, 24, 35, 0.03);
 }

 .post .blog-tags-item i {
   font-size: 18px;
 }

 .post .blog-tags-item svg {
   color: #555;
 }

 .post .post-content img {
   max-width: 100%;
 }

 .post .post-bottom {
   padding: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
 }

 .post .post-bottom .post-bottom-left {
   display: flex;
   flex-direction: row;

 }

 .post .post-bottom .post-bottom-left .post-bottom-icon,
 .post .post-bottom .post-bottom-right {
   padding: 0.5rem 1rem;
   border-radius: 0.5rem;
   border: 1px solid #e8e8e8;
   cursor: pointer;
 }

 .post .post-bottom .post-bottom-left .post-bottom-icon:hover,
 .post .post-bottom .post-bottom-right:hover {
   background-color: #e8e8e8;
   transition: 0.5s;
 }

 .post .post-bottom .post-bottom-left .post-bottom-icon,
 .post .post-bottom .post-bottom-right {
   display: flex;
   align-items: center;
 }

 .post .post-bottom .post-bottom-left .post-bottom-icon .icon,
 .post .post-bottom .post-bottom-right .icon {
   font-size: 1.25rem;
   color: #333
 }

 .post .post-bottom .post-bottom-left .post-bottom-icon.active .icon {
   color: rgb(254, 44, 85);
 }

 .post .post-bottom .post-bottom-left .post-bottom-icon span {
   margin-left: 0.5rem;
 }

 .post .post-bottom .post-bottom-left .post-bottom-icon:last-child {
   margin-left: 1rem;
 }

 .post .post-comments {
   padding: 0 1rem 1rem;
 }

 @media screen and (min-width: 768px) {
   .post {
     margin: 1rem;
   }
 }