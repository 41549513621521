svg.spinner-circle {
  width: 40px;
  height: 40px;
}

svg.spinner-circle circle {
  fill: transparent;
  stroke: #212121;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: 125.6;
  -webkit-transform-origin: 20px 20px 0;
  -moz-transform-origin: 20px 20px 0;
  -ms-transform-origin: 20px 20px 0;
  -o-transform-origin: 20px 20px 0;
  transform-origin: 20px 20px 0;
  -webkit-animation: spinner-circle 2s linear infinite;
  -moz-animation: spinner-circle 2s linear infinite;
  -ms-animation: spinner-circle 2s linear infinite;
  -o-animation: spinner-circle 2s linear infinite;
  animation: spinner-circle 2s linear infinite;
}

@-webkit-keyframes spinner-circle {
  0% {
    -webkit-transform: rotate(0deg);
    stroke-dashoffset: 26.4;
  }

  50% {
    -webkit-transform: rotate(720deg);
    stroke-dashoffset: 125.6;
  }

  100% {
    -webkit-transform: rotate(1080deg);
    stroke-dashoffset: 26.4;
  }
}

@-moz-keyframes spinner-circle {
  0% {
    -moz-transform: rotate(0deg);
    stroke-dashoffset: 26.4;
  }

  50% {
    -moz-transform: rotate(720deg);
    stroke-dashoffset: 125.6;
  }

  100% {
    -moz-transform: rotate(1080deg);
    stroke-dashoffset: 26.4;
  }
}

@-ms-keyframes spinner-circle {
  0% {
    -ms-transform: rotate(0deg);
    stroke-dashoffset: 26.4;
  }

  50% {
    -ms-transform: rotate(720deg);
    stroke-dashoffset: 125.6;
  }

  100% {
    -ms-transform: rotate(1080deg);
    stroke-dashoffset: 26.4;
  }
}

@-o-keyframes spinner-circle {
  0% {
    -o-transform: rotate(0deg);
    stroke-dashoffset: 26.4;
  }

  50% {
    -o-transform: rotate(720deg);
    stroke-dashoffset: 125.6;
  }

  100% {
    -o-transform: rotate(1080deg);
    stroke-dashoffset: 26.4;
  }
}

@keyframes spinner-circle {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 26.4;
  }

  50% {
    transform: rotate(720deg);
    stroke-dashoffset: 125.6;
  }

  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 26.4;
  }
}