.blog-comment {
  display: flex;
  flex-direction: column;
}

.blog-comment:not(:first-child) {
  margin-top: 16px;
  margin-bottom: 16px;
}

.blog-comment__media {
  display: flex;
  flex-direction: row;
}

.blog-comment__media-title img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.blog-comment__media-body {
  margin-left: 16px;
  position: relative;
  width: 100%;
}

.blog-comment__media-body-title {
  display: flex;
  flex-direction: column;
}

.blog-comment__media-body-title .username {
  text-decoration: none;
  color: #5c5c5c;
  font-weight: 600;
}

.blog-comment__media-body-title .username:hover {
  text-decoration: underline;
  transition: 0.3s ease-in-out;
}

.blog-comment__media-body-title p {
  color: #8a8a8a;
}

.blog-comment-text {
  margin-top: 8px;
  margin-right: 2rem;
  word-wrap: break-word;
  word-break: break-all;
  color: #333;
}

.blog-comment-actions {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.blog-comment-actions p {
  font-size: 14px;
  color: rgba(22, 24, 35, 0.5);
  cursor: pointer;
}

.blog-comment-actions p:hover {
  color: rgba(22, 24, 35, 0.6);
  transition: 0.3s ease-in-out;
}

.blog-comment-actions p:first-child {
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.blog-comment-actions p:not(:first-child) {
  padding: 0.5rem;
  font-weight: 600;
}

.blog-comment-actions .reply.active,
.blog-comment-actions .edit.active {
  color: rgba(22, 24, 35, 0.8);
}

.blog-comment-actions .cancel {
  color: rgba(255, 137, 59, 1);
}

.blog-comment-actions .cancel:hover {
  color: rgba(22, 24, 35, 0.6);
}

.blog-comment-actions .delete:hover {
  color: rgba(219, 26, 26, 0.8);
}

.blog-comment-like {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}

.blog-comment-like .icon {
  font-size: 18px;
  color: #aaa;
  cursor: pointer;
}

.blog-comment-like.active .icon {
  color: rgb(254, 86, 119);
}

.blog-comment-like span {
  color: rgba(22, 24, 35, 0.5);
  margin-left: 2px;
}

@media screen and (max-width: 576px) {
  .blog-comment-text {
    margin-right: 0;
  }
}