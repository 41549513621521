.trade-input {
  padding: 32px 32px 64px;
  position: relative;
  overflow-y: auto;
}

.trade-input .content {
  padding: 0px;
}

.trade-input .content .image-upload-container {
  width: 100%;
  margin-bottom: 25px;
}

.trade-input .content .input-wrapper {
  width: 100%;
  position: relative;
}

.trade-input .content .input-wrapper label {
  display: inline-block;
  color: #212529;
  font-size: 14px;
  margin-bottom: 8px;
}

.trade-input .content .input-wrapper .invalid-feedback {
  position: absolute;
  bottom: -16px;
  left: 6px;
  font-size: 13px;
  color: #dc3545;
}

.trade-input .content .input-container,
.trade-input .content .select-container {
  width: 100%;
  margin-bottom: 16px;
}

.trade-input .content .input-group,
.trade-input .content .select-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
}

.trade-input .content .input-group input,
.trade-input .content .input-group .react-datepicker__input-container input,
.trade-input .content .input-group .react-datepicker-time__input input {
  width: 100%;
  height: 36px;
  padding: 8px 12px;
  color: #4b4b4b;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.trade-input .content .input-group input::placeholder {
  color: rgb(133, 133, 133);
}

.trade-input .content .image-upload-container {
  margin-bottom: 25px
}

.trade-input .content .image-upload-container .image-drop-container {
  height: 150px;
}

.trade-input .content .btn-update-container {
  width: 100%;
  height: 20px;
}

.trade-input .content .btn-update-container .btn-create,
.trade-input .content .btn-update-container .btn-update,
.trade-input .content .btn-update-container .btn-cancel {
  padding: 10px 20px;
  float: right;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 5px;
  border-color: transparent;
}

.trade-input .content .btn-update-container .btn-create {
  background-color: #28a745;
  color: rgba(0, 0, 0, 0.7);
}

.trade-input .content .btn-update-container .btn-create:hover {
  background-color: #238d3b;
  transition: background-color 0.3s ease-out;
}

.trade-input .content .btn-update-container .btn-create:disabled {
  background-color: #6dd886;
  cursor: not-allowed;
}


.trade-input .content .btn-update-container .btn-update {
  background-color: #ffe08a;
  color: rgba(0, 0, 0, 0.7);
}

.trade-input .content .btn-update-container .btn-update:disabled {
  background-color: #e4ce8f;
  cursor: not-allowed;
}

.trade-input .content .btn-update-container .btn-update:hover {
  background-color: #ffdc7d;
  transition: background-color 0.3s ease-out;
}

.trade-input .content .btn-update-container .btn-success {
  background-color: #48c78e;
  color: #fff;
}

.trade-input .content .btn-update-container .btn-success:hover {
  background-color: #3ec487;
  transition: background-color 0.3s ease-out;
}

.trade-input .content .btn-update-container .btn-cancel {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.7);
}

.trade-input .content .btn-update-container .btn-cancel:hover {
  background-color: #eee;
  transition: background-color 0.3s ease-out;
}

@media screen and (max-width: 768px) {
  .trade-input .content .btn-update-container {
    padding-top: 25px;
  }
}