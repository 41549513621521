.trade-table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  table-layout: fixed;
  display: table;
  width: 100%;
}

.trade-table thead,
.trade-table tbody {
  display: block;
  width: 100%;
}

.trade-table tbody {
  height: 180px;
  overflow-y: auto;
}

.trade-table td,
.trade-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.trade-table tr th:first-child,
.trade-table tr td:first-child {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.trade-table tr th:last-child,
.trade-table tr td:last-child {
  min-width: 250px;
  width: 100%;
}

@media (max-width: 1024px) {

  .trade-table tr th:first-child,
  .trade-table tr td:first-child {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
  }

  .trade-table tr th:last-child,
  .trade-table tr td:last-child {
    min-width: 150px;
    width: 100%;
  }
}

.trade-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.trade-table tr:hover {
  background-color: #ddd;
}

.trade-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}

.trade-table thead.win th {
  background-color: #04AA6D;
}

.trade-table thead.loss th {
  background-color: #e85154;
}

.trade-table tbody td.positive {
  color: #04AA6D;
}

.trade-table tbody td.negative {
  color: #ba1b1e;
}

.trade-table td.win {
  color: #04AA6D;
}

.trade-table td.loss {
  color: #ba1b1e;
}