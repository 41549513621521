.text-card-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.text-card-responsive .text-card {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.text-card {
  height: 650px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-card h1 {
  color: #444;
  text-align: center;
  line-height: 1.6;
  padding: 8px;
}