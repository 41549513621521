.upload {
  display: flex;
  margin-top: 60px;
}

.upload-container {
  background-color: rgb(238, 238, 238);
  width: 100%;
}

.upload-wrapper {
  margin: 0px auto;
  max-width: 1140px;
  padding: 20px;
}

.upload-content {
  box-shadow: rgb(0 0 0 / 6%) 0px 2px 8px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  padding: 24px 56px;
  min-height: calc(100vh - 60px - 2 * 20px);
}

.upload-content h2 {
  font-size: 24px;
  color: rgb(22, 24, 35);
  font-weight: 600;
  line-height: 32px;
}

.upload-content h4 {
  font-size: 18px;
  color: rgb(138, 139, 145);
  font-weight: 400;
  line-height: 24px;
  margin-top: 2px;
}