.settings {
  display: flex;
  margin-top: 60px;
}

.settings-container {
  background-color: rgb(238, 238, 238);
  width: 100%;
}

.settings-wrapper {
  margin: 0px auto;
  max-width: 1140px;
  padding: 20px;
}

.settings-content {
  box-shadow: rgb(0 0 0 / 6%) 0px 2px 8px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  min-height: calc(100vh - 60px - 2 * 20px);
}

.settings-content-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.settings-content-left {
  flex: 3;
  border: 1px solid #eee;
  min-height: calc(100vh - 60px - 2 * 20px);
}

.profile {
  width: 100%;
  padding: 30px 25px;
  text-align: center;
  color: #333;
}

.profile img {
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile h4 {
  margin-bottom: 10px;
}

.settings-menu {
  display: flex;
  flex-direction: column;
}

.settings-menu .tab:first-child {
  border-top: 1px solid #ddd;
}

.settings-menu .tab {
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  color: #333;
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.settings-menu .tab.active {
  background-color: #007bff;
  color: #fff;
}

.settings-menu .tab .icon {
  margin-right: 12px;
}

.settings-content-right {
  flex: 9;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  width: 100%;
}

.settings-tab {
  position: relative;
}

.settings-tab .title {
  color: #212529;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.75rem;
}

.settings-tab .description {
  color: #212529;
  font-size: 1rem;
}

.settings-tab .content {
  margin-top: 20px;
  /* margin-bottom: 30px; */
  background-color: #f7f8f9;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

.settings-tab .content p.bold {
  font-weight: 600;
  margin-bottom: 10px;
}

/*wallet and backup */
.settings-tab .create-wallet,
.settings-tab .create-backup {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #48c78e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.settings-tab .create-wallet:hover,
.settings-tab .create-backup:hover {
  background-color: #3ec487;
  transition: 0.5s ease-in-out;
}

.settings-tab .create-wallet .icon,
.settings-tab .create-backup .icon {
  font-size: 1.5rem;
}

.profile-tab .wallet,
.profile-tab .backup {
  margin-top: 20px;
  margin-bottom: 20px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table thead th:not(:first-child) {
  text-align: left;
  padding-left: 0.75rem;
}

.table tbody td,
.table tbody th {
  padding: 0.75rem;
  word-wrap: break-word;
  word-break: break-all;
  border-bottom: 1px solid #dee2e6;
}

.table tbody td.deposit {
  color: green;
}

.table tbody td.withdrawal {
  color: red;
}

.table .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
}

.table .badge {
  margin-left: 0.25rem;
}

.table .badge-success {
  color: #fff;
  background-color: #28a745;
}

.table .badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.table .badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.table .badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.table .group-btn {
  display: flex;
  gap: 8px;
}

.table .update-btn {
  color: #656565;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 16px;
  background-color: rgb(255, 224, 138);
  border: none;
  outline: none;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
}

.table .update-btn:hover {
  background-color: rgb(255, 220, 125);
  transition: 0.3s ease-in-out;
}

.table .delete-btn {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 16px;
  background-color: rgba(220, 38, 38, 1);
  border: none;
  outline: none;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
}

.table .delete-btn:hover {
  background-color: rgba(220, 38, 38, 0.9);
  transition: 0.3s ease-in-out;
}

/*users*/
.form-roles {
  margin-left: 0.25rem;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-label {
  margin-bottom: 0;
}

.form-roles-action {
  margin-top: 0.25rem;
  display: flex;
  flex-direction: row;
}

.form-roles-action button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.125rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  cursor: pointer;
}

.form-roles-action button:hover {
  background-color: #d2d6da;
  border-color: #cad0d5;
}

.form-roles-action button:first-child {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.form-roles-action button:first-child:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.form-roles-action button:last-child {
  margin-left: 0.5rem;
}

.badges {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width:768px) {
  .settings-content-right {
    margin-left: 10px;
    margin-right: 10px;
  }

  .settings-tab .content {
    padding: 10px;
  }

  .form-roles-action,
  .badges {
    display: flex;
    flex-direction: column;
  }

  .form-roles-action button:last-child,
  .badges .badge {
    margin-top: 0.25rem;
    margin-left: 0;
  }

  .badges-wrapper {
    display: flex;
    justify-content: center;
  }

  .badges {
    width: 80px;
  }

  .badges .badge:not(:first-child) {
    margin-top: 0.25rem;
  }
}

@media only screen and (min-width:501px) and (max-width:768px) {
  .settings-menu .tab .icon-ex {
    font-size: 24px;
  }
}


@media only screen and (max-width:500px) {
  .settings-content-left {
    flex: 1;
  }

  .settings-content-right {
    flex: 5;
  }

  .settings-menu .tab .icon {
    margin-right: 0;
  }

  .profile h4,
  .settings-menu .tab span {
    display: none;
  }

  .profile {
    width: 100%;
    padding: 30px 10px;
  }

  .profile img {
    width: 35px;
  }

  .table thead th:not(:first-child) {
    padding-left: 0.25rem;
  }

  .table tbody td,
  .table tbody th {
    padding: 0.25rem;
  }

  .table .icon {
    display: none;
  }
}