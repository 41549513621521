.blog-comments,
.blog-comments-child {
  list-style: none;
}

.blog-comments-child {
  margin-left: 50px;
}

.blog-comments-child .blog-comment:first-child {
  margin-top: 16px;
}

.blog-comments,
.blog-comments-child {
  list-style: none;
}

.blog-comments-child {
  margin-left: 50px;
}

.blog-comments-child .blog-comment:first-child {
  margin-top: 16px;
}

.view-previous-comments {
  padding: 0 1rem 0;
}

.view-previous-comments p {
  color: #777;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.view-previous-comments p:hover {
  text-decoration: underline;
}