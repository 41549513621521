@import "./../../styles/variables.scss";
@import "./../../styles/responsive.scss";

.app-layout {
  font-family: "Inter", sans-serif;
  background-color: $main-bg;
  color: $main-color;
  display: flex;
  flex-direction: row;
}

.sidebar-container {
  width: 250px;
  height: 100vh;
  border-right: 2px solid $soft-bg;

  @include lg {
    min-width: 70px;
    max-width: 70px;
    display: flex;
    justify-content: center;
  }
}

.app-container {
  width: calc(100vw - 250px);
  display: flex;
  flex-direction: column;
  background-color: $soft-bg;

  @include lg {
    width: calc(100vw - 70px);
  }
}

.content-container {
  padding: 5px 20px;
  width: 100%;
}