.toast-container {
  position: fixed;
  z-index: 99999
}

.toast-container.top-right {
  top: 12px;
  right: 12px;
}

.toast-container.bottom-right {
  bottom: 12px;
  right: 12px;
}

.toast-container.top-left {
  top: 12px;
  left: 12px;
}

.toast-container.bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}