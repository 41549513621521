.blog-modal {
  user-select: none;
  background: #fff;
  max-width: 580px;
  width: 100%;
  border-radius: 15px;
  padding: 25px 25px 15px 25px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  opacity: 1;
  pointer-events: none;
  transition: all 0.3 ease-in-out;
}

.blog-modal.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blog-modal.active {
  opacity: 1;
  pointer-events: all;
}

.blog-modal-overlay {
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
  transition: all 0.3 ease-in-out;
  z-index: 1999;
}

.blog-modal-overlay.active {
  opacity: 1;
  pointer-events: all;
}

.image-grid-preview {
  position: relative;
}

.image-grid-preview:hover {
  background-color: #f2f2f2;
}

.image-preview-add-more {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 18px;
  border-radius: 5px;
  padding: 5px 8px;
  border: 1px solid #b5b5b5;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2002;
}

.image-preview-add-more:hover {
  background-color: #f5f5f5;
  transition: 0.5 ease-in-out;
}

.image-preview-add-more span {
  margin-left: 5px;
}


.blog-modal-close,
.image-preview-close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #e5e5e5;
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.blog-modal-close {
  z-index: 3002;
}

.blog-modal-close:hover {
  background-color: #d5d5d5;
  transition: 0.5 ease-in-out;
}

.image-preview-close {
  border: 1px solid #ccc;
  background-color: #fff;
}

.image-preview-close:hover {
  background-color: #f5f5f5;
  transition: 0.5 ease-in-out;
}

.blog-modal .input-box {
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.blog-modal .input-box::-webkit-scrollbar {
  width: 8px;
}

.blog-modal .input-box::-webkit-scrollbar-track {
  margin-top: 45px;
  background: #f1f1f1;
  box-shadow: inset 0 0 5px #ccc;
  border-radius: 10px;
}

.blog-modal .input-box::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.blog-modal .input-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blog-modal .input-box .tweet-area {
  position: relative;
}

.blog-modal .tweet-area::-webkit-scrollbar {
  width: 0px;
}

.blog-modal .blog-modal-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-modal .privacy,
.blog-modal .blog-tags-item {
  color: #1da1f2;
  margin: 5px 0;
  display: inline-flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.blog-modal .blog-tags-item {
  margin-left: 5px;
  padding: 3px 10px;
  border: 1px solid rgba(22, 24, 35, 0.2);
  color: #888;
}

.blog-modal .privacy:hover,
.blog-modal .icons li:hover {
  background: #e7f5fe;
}

.blog-modal .blog-tags-item:hover {
  background: rgba(22, 24, 35, 0.03);
}

.blog-modal .privacy,
.blog-modal .blog-tags-item i {
  font-size: 18px;
}

.blog-modal .blog-tags-item svg {
  color: #555;
}

.blog-modal .privacy span,
.blog-modal .blog-tags-item span {
  font-size: 15px;
  font-weight: 600;
  margin-left: 7px;
}

.blog-modal .audience,
.blog-modal .blog-tags-select {
  transition: opacity 0.12s ease;
  position: absolute;
  top: 10px;
  bottom: 60px;
  right: 25px;
  left: 25px;
  background-color: #fff;
  border-radius: 20px;
  z-index: 3001;
}

.blog-modal .audience header,
.blog-modal .blog-tags-select header {
  padding: 17px 0;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #bfbfbf;
}

.blog-modal .audience header .arrow-back,
.blog-modal .blog-tags-select header .arrow-back {
  position: absolute;
  left: 25px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-size: 15px;
  color: #747474;
  border-radius: 50%;
  background: #E4E6EB;
}

.blog-modal .audience :where(.arrow-back, .icon, li .radio),
.blog-modal .blog-tags-select :where(.arrow-back) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-modal .audience header p,
.blog-modal .blog-tags-select header p {
  font-size: 22px;
  font-weight: 600;
}

.blog-modal .audience .content {
  margin: 10px 0;
}

.blog-modal .blog-tags-select .content {
  padding: 2rem 1rem;
}

.blog-modal .audience .content p {
  font-size: 17px;
  font-weight: 500;
}

.blog-modal .audience .content span {
  font-size: 14px;
  color: #65676B;
}

.blog-modal .audience .list {
  margin: 15px 16px 15px;
  list-style: none;
}

.blog-modal .audience .list li {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 12px 10px;
  border-radius: 7px;
  align-items: center;
  justify-content: space-between;
}

.blog-modal .audience .list li .column {
  display: flex;
  align-items: center;
}

.blog-modal .list li.active,
.blog-modal .audience .list li.active:hover {
  background: #E5F1FF;
}

.blog-modal .audience .list li:hover {
  background: #f0f1f4;
}

.blog-modal .audience .list li .column .icon {
  height: 50px;
  width: 50px;
  color: #333;
  font-size: 23px;
  border-radius: 50%;
  background: #E4E6EB;
}

.blog-modal .audience .list li.active .column .icon {
  background: #cce4ff;
}

.blog-modal .audience .list li .column .details {
  margin-left: 15px;
}

.blog-modal .list li .column .details p {
  font-weight: 500;
}

.blog-modal .list li .column .details span {
  font-size: 14px;
  color: #65676B;
}

.blog-modal .list li .radio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #707070;
}

.blog-modal .list li.active .radio {
  border: 2px solid #1da1f2;
}

.blog-modal .list li .radio::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: inherit;
}

.blog-modal .list li.active .radio::before {
  background: #1da1f2;
}

.blog-modal .bottom-line {
  padding: 5px 0 0;
  border-top: 1px solid #e6e6e6;
}

.blog-modal .bottom {
  display: flex;
  margin-top: 13px;
  align-items: center;
  justify-content: space-between;
}

.blog-modal .bottom .icons {
  display: inline-flex;
}

.blog-modal .icons li {
  list-style: none;
  color: #1da1f2;
  font-size: 20px;
  margin: 0 2px;
  height: 38px;
  width: 38px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background 0.2s ease;
}

.blog-modal .bottom .content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-modal .bottom button {
  padding: 9px 18px;
  border: none;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  background: #1da1f2;
  color: #fff;
  cursor: pointer;
  opacity: 0.5;
  pointer-events: none;
  transition: background 0.2s ease;
}

.blog-modal .bottom button.active {
  opacity: 1;
  pointer-events: auto;
}

.blog-modal .bottom button:hover {
  background: #0d8bd9;
}

.blog-modal .editor {
  width: 100%;
}

.blog-upload {
  width: 100%;
  margin: 1rem 0;
  padding-bottom: 1rem;
  position: relative;
  border: 1px solid transparent;
}

.blog-upload:hover {
  border: 1px solid #f5f5f5;
  transition: all 0.5 ease-in-out;
}

.blog-upload__close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.blog-upload__close:hover {
  background-color: #f5f5f5;
  transition: 0.5 ease-in-out;
}

.blog-upload .drop-container {
  margin: 3rem 1rem 1rem;
}

@media only screen and (min-width:960px) {
  .blog-modal .input-box {
    min-height: 370px;
    max-height: 450px;
    overflow-y: auto;
  }
}

@media only screen and (max-width:600px) {
  .blog-modal {
    max-width: 420px;
  }
}

@media only screen and (max-width:420px) {
  .blog-modal {
    max-width: 350px;
    padding: 15px;
  }
}